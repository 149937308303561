
import { PropType } from "@vue/runtime-core";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    /**
     * The filter options and default values
     */
    values: {
      type: Object as PropType<Record<string, Record<string, boolean>>>,
      required: true,
    },
    /**
     * If submit button should be shown
     */
    showSubmit: {
      type: Boolean,
      required: false,
      default: false,
    },
    /**
     * The title for the filter section
     */
    filterTitle: {
      type: String,
      required: false,
      default: "",
    },
    /**
     * If reset button should be shown
     */
    showReset: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    /**
     * Gets called when user triggers a change
     */
    onChange(group_key: string, option_key: string, value: boolean) {
      let values = this.values;
      values[group_key][option_key] = value;
      this.$emit("selectionChanged", values);
    },
    /**
     * Gets called when the user clicks on the reset button
     */
    reset() {
      let values = this.values;
      for (let group_key in values) {
        for (let option_key in values[group_key]) {
          values[group_key][option_key] = false;
        }
      }
      this.$emit("selectionChanged", values);
    },
  },
})
export default class Filter extends Vue {}
