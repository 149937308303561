import { FeeDataExportType } from "@/interfaces/api";
import BaseService from "@/services/base-service";

class DownloadService extends BaseService {
  /**
   * Downloads an excel file containing fee data
   *
   * @param {string} locale - The language of the excel file to download
   * @param {string} fromDateIsoString - Include note-taker fee data from this date forward
   * @param {FeeDataExportType} feeDataExportType - The type of fee data export
   *
   * @returns {Promise<boolean>} A Promise with a boolean representing the success of the operation
   */

  async downloadFeeData(
    locale: string,
    fromDateIsoString: string,
    feeDataExportType: FeeDataExportType
  ): Promise<boolean> {
    const endpointController = "Download";
    const enpointRoute = "feedata";
    const paramName_1 = "locale";
    const paramValue_1 = locale;
    const paramName_2 = "fromDate";
    const paramValue_2 = fromDateIsoString;
    const paramName_3 = "feeDataExportType";
    const paramValue_3 = feeDataExportType;
    const queryString = `${paramName_1}=${paramValue_1}&${paramName_2}=${paramValue_2}&${paramName_3}=${paramValue_3}`;
    const url = `${this.baseApiUrl}api/${endpointController}/${enpointRoute}?${queryString}`;

    return await this.fetch<boolean>(url);
  }

  /**
   * Downloads a pdf file containing invoice data
   *
   * @param {string} invoiceNo - The invoice number of the pdf file to download
   *
   * @returns {Promise<boolean>} A Promise with a boolean representing the success of the operation
   */
  async downloadInvoice(invoiceNo: string): Promise<boolean> {
    const endpointController = "Download";
    const enpointRoute = "invoice";
    const paramName = "invoiceNo";
    const paramValue = invoiceNo;
    const queryString = `${paramName}=${paramValue}`;
    const url = `${this.baseApiUrl}api/${endpointController}/${enpointRoute}?${queryString}`;

    return await this.fetch<boolean>(url);
  }
}

export default new DownloadService();
