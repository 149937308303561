import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex justify-content-between"
}
const _hoisted_2 = {
  key: 0,
  class: "m-0"
}
const _hoisted_3 = { class: "mb-2" }
const _hoisted_4 = ["id", "checked", "onChange"]
const _hoisted_5 = ["for"]
const _hoisted_6 = {
  key: 0,
  class: "mt-3 text-center"
}
const _hoisted_7 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.filterTitle.length > 0 || _ctx.showReset)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.filterTitle.length > 0)
            ? (_openBlock(), _createElementBlock("h3", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.filterTitle)), 1))
            : _createCommentVNode("", true),
          (_ctx.showReset)
            ? (_openBlock(), _createElementBlock("a", {
                key: 1,
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.reset && _ctx.reset(...args))),
                class: "d-block",
                role: "button"
              }, _toDisplayString(_ctx.$t("form.filter-reset")), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.values, (group, group_key) => {
        return (_openBlock(), _createElementBlock("fieldset", {
          class: "form-group",
          key: group_key
        }, [
          _createElementVNode("legend", null, [
            _renderSlot(_ctx.$slots, "group-title", { group: group_key }, () => [
              _createTextVNode(_toDisplayString(group_key), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(group, (option, option_key) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "custom-control custom-checkbox form-check-inline",
                style: {"display":"inline-flex","margin-right":"3rem"},
                key: option_key
              }, [
                _createElementVNode("input", {
                  type: "checkbox",
                  class: "custom-control-input",
                  id: `option-${group_key}-${option_key}`,
                  checked: option,
                  onChange: ($event: any) => (_ctx.onChange(group_key, option_key, $event.target.checked))
                }, null, 40, _hoisted_4),
                _createElementVNode("label", {
                  class: "custom-control-label",
                  for: `option-${group_key}-${option_key}`
                }, [
                  _renderSlot(_ctx.$slots, "option-title", { option: option_key }, () => [
                    _createTextVNode(_toDisplayString(option_key), 1)
                  ])
                ], 8, _hoisted_5)
              ]))
            }), 128))
          ])
        ]))
      }), 128)),
      (_ctx.showSubmit)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("input", {
              type: "submit",
              class: "btn btn-primary",
              value: _ctx.$t('form.apply')
            }, null, 8, _hoisted_7)
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}