import QuerystringHelper from "@/helpers/querystring-helper";
import {
  NoteTaker,
  NoteTakerStatusCount,
  NoteTakerParameters,
  PagedList,
  OverviewNoteTaker,
} from "@/interfaces/api";
import BaseService from "./base-service";

class NoteTakerService extends BaseService {
  /**
   * Fetches note takers according to the specified filter/paging parameters
   *
   * @param {NoteTakerParameters} noteTakerParameters - The filter/paging parameters to be added to the resource querystring
   * @returns {Promise<PagedList<NoteTaker>>} A Promise with all the note takers that matched the parameters
   */
  getFiltered(
    noteTakerParameters: NoteTakerParameters
  ): Promise<PagedList<OverviewNoteTaker>> {
    const endpointController = "NoteTaker";
    const enpointRoute = "search";
    const queryString = this.generateQuerystring(noteTakerParameters);
    const getNoteTakerById = `${this.baseApiUrl}api/${endpointController}/${enpointRoute}${queryString}`;
    return this.fetch<PagedList<OverviewNoteTaker>>(getNoteTakerById);
  }

  /**
   * Fetches all note takers
   * @returns {Promise<Array<NoteTaker>>} A Promise with all note takers
   */
  getAllNoteTakers(): Promise<Array<NoteTaker>> {
    const endpointController = "NoteTaker";
    const enpointRoute = "one-call";
    const getAllNoteTakers = `${this.baseApiUrl}api/${endpointController}/${enpointRoute}`;
    return this.fetch<Array<NoteTaker>>(getAllNoteTakers);
  }

  /**
   * Fetches the note taker with the specified id
   *
   * @param {number} id - The id of the note taker to fetch
   * @returns {Promise<NoteTaker | undefined>} A Promise with the note taker
   */
  getNoteTakerById(id: number): Promise<NoteTaker | undefined> {
    const endpointController = "NoteTaker";
    const enpointRoute = "id";
    const parameterName = "id";
    const parameterArgument = id;
    const getNoteTakerById = `${this.baseApiUrl}api/${endpointController}/${enpointRoute}?${parameterName}=${parameterArgument}`;
    return this.fetch<NoteTaker>(getNoteTakerById);
  }

  /**
   * Fetches the note taker with the specified luEduPersonPrimaryId
   *
   * @param {string} luEduPersonPrimaryId - The luEduPersonPrimaryId of the note taker to fetch
   * @returns {Promise<NoteTaker | undefined>} A promise with the applicant
   */
  getApplicant(luEduPersonPrimaryId: string): Promise<NoteTaker | undefined> {
    const endpointController = "NoteTaker";
    const enpointRoute = "primaryId";
    const parameterName = "luEduPersonPrimaryId";
    const parameterArgument = luEduPersonPrimaryId;
    const getApplicant = `${this.baseApiUrl}api/${endpointController}/${enpointRoute}?${parameterName}=${parameterArgument}`;
    return this.fetch<NoteTaker>(getApplicant);
  }

  /**
   * Fetches the count of statuses
   *
   * @returns {Promise<NoteTakerStatusCount[] | undefined>} A promise with the count of statuses
   */
  getNoteTakerStatusCount(): Promise<NoteTakerStatusCount[] | undefined> {
    const endpointController = "NoteTaker";
    const enpointRoute = "statusCount";
    const getStatus = `${this.baseApiUrl}api/${endpointController}/${enpointRoute}`;
    return this.fetch<NoteTakerStatusCount[]>(getStatus);
  }

  /**
   * Adds or updates a note taker
   *
   * @param {NoteTaker} noteTaker - The note taker to Add och Update
   * @returns {Promise<boolean>} A promise with a boolean value indicating whether or not the operation was successful
   */
  addOrUpdateNoteTaker(noteTaker: NoteTaker): Promise<boolean> {
    const endpointController = "NoteTaker";
    const addOrUpdateNoteTaker = `${this.baseApiUrl}api/${endpointController}`;
    return this.fetch<boolean>(addOrUpdateNoteTaker, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(noteTaker),
    });
  }

  /**
   * Returns a query string according to the specified filter parameters
   *
   * @param {NoteTakerParameters} noteTakerParameters - The filter/paging parameters to be added to the resource querystring
   * @returns {string} The generated query string
   */
  private generateQuerystring(
    noteTakerParameters: NoteTakerParameters
  ): string {
    let queryString = "?";

    if (noteTakerParameters.status.length > 0) {
      noteTakerParameters.status.forEach((status) => {
        queryString += `Status=${status}&`;
      });
    }

    queryString +=
      QuerystringHelper.generateSearchAndPagingParameters(noteTakerParameters);

    return queryString;
  }
}

export default new NoteTakerService();
