
import { Options, Vue } from "vue-class-component";
import Accordion from "@/components/Accordion.vue";
import DatePicker from "@/components/Form/DatePicker.vue";
import Filter from "@/components/Form/Filter.vue";
import Search from "@/components/Form/Search.vue";
import Modal from "@/components/Modal.vue";
import StudentsColumn from "@/components/NoteTakerList/StudentsColumn.vue";
import Table from "@/components/Table.vue";
import { Constants } from "@/constants/constants";
import {
  FeeDataExportType,
  OverviewNoteTaker,
  NoteTakerParameters,
  NoteTakerStatus,
  // NoteTakerStatusCount,
  PagedList,
  SortOrder,
} from "@/interfaces/api";
import { IPagingData } from "@/interfaces/IPagingData";
import { IUserPagingData } from "@/interfaces/IUserPagingData";
import DownloadService from "@/services/download-service";
import LocalStorageService from "@/services/localstorage-service";
import NoteTakerService from "@/services/notetaker-service";

@Options({
  components: {
    Search,
    Filter,
    Table,
    Accordion,
    StudentsColumn,
    Modal,
    DatePicker,
  },
  data: () => ({
    /**
     * Holds the count of statuses
     */
    noteTakerStatusCount: null,
    /**
     * The current sort column
     */
    sortColumn: "personalId",
    /**
     * Unsortable columns
     */
    unsortableColumns: ["students"],
    /**
     * If sort order is ascending
     */
    ascending: false,
    /**
     * The current search value
     */
    search: "",
    /**
     * All rows in the table
     */
    rows: [],
    /**
     * The url to detailed view
     */
    navigateToDetailsUrl: "/administrator/notetakerdetails/",
    /**
     * Holds the selected filter values for status
     */
    selectedStatus: [],
    /**
     * Holds the selected filter values for password sent
     */
    selectedPasswordSent: [],
    /**
     * Holds the paging data sent back with the list of note takers from the backend
     */
    pagingData: {},
    /**
     * Used to delay search phrase changes calling backend
     */
    timer: null,
    /*
     * Populates the status badges
     */
    statusOptions: Object.values(NoteTakerStatus),
    /**
     * If the data is still loading - passed to the table component to show spinner
     */
    isLoading: true,
    /**
     * Used for exporting fee basis data - populated from the date picker
     */
    feeExportFromDate: null,
    /**
     * Used for setting button action in export modal
     */
    feeExportType: null,
  }),
  computed: {
    /**
     * Hides all unwanted columns
     */
    hiddenColumns() {
      const hidden = ["id", "luEduPersonPrimaryId", "status"];
      return hidden;
    },
    /**
     * Holds the filter values for statuses
     */
    statusFilter() {
      return {
        status: {
          Incoming: this.populateFilterValue("selected_status_incoming", true),
          Active: this.populateFilterValue("selected_status_active", false),
          Inactive: this.populateFilterValue("selected_status_inactive", false),
          Completed: this.populateFilterValue(
            "selected_status_completed",
            false
          ),
        },
      };
    },
    /**
     * The page heading to be displayed above the table
     */
    pageHeading() {
      return this.$t("shared.routes.note-taker-overview");
    },
    locale(): string {
      return this.$root.$i18n.locale;
    },
    exportTypeBasis(): string {
      return FeeDataExportType.Basis;
    },
    exportTypeAccounting(): string {
      return FeeDataExportType.Accounting;
    },
  },
  methods: {
    populateFilterValue(
      localstorageKey: string,
      defaultValue: boolean
    ): boolean {
      return LocalStorageService.keyExists(localstorageKey)
        ? (LocalStorageService.getItem<boolean>(localstorageKey) as boolean)
        : defaultValue;
    },
    /**
     * Iterates a filter and saves selected options to localstorage
     */
    iterateFilter(
      filter: { [x: string]: { [x: string]: boolean } },
      array: string[],
      filterType: string
    ): void {
      for (let group_key in filter) {
        for (let option_key in filter[group_key]) {
          const storageKey = `selected_${filterType}_${option_key}`;

          if (filter[group_key][option_key]) {
            array.push(option_key);
            LocalStorageService.setItem(storageKey, "true");
          } else {
            LocalStorageService.setItem(storageKey, "false");
          }
        }
      }
    },
    /**
     * Fetches note takers that meet the required search, filter and paging data
     */
    getNoteTakers(): void {
      const parameters = this.getNoteTakerParameters();
      this.isLoading = true;

      NoteTakerService.getFiltered(parameters)
        .then((data: PagedList<OverviewNoteTaker>) => {
          this.populatePagingData(data);
          this.rows = data.items;
          this.saveFilteredNoteTakerIds(data.sortedIds);
          this.isLoading = false;
        })
        .catch((e: Error) => {
          console.error(e);
        });
    },
    /**
     * Populates and returns parameters for the getFiltered method in the note taker service
     */
    getNoteTakerParameters(): NoteTakerParameters {
      return {
        search: this.search.toLowerCase(),
        sortBy: this.sortColumn,
        sortOrder: this.ascending ? SortOrder.Asc : SortOrder.Desc,
        pageNumber: this.pagingData.currentPage || 1,
        pageSize: this.pagingData.pageSize || 10,
        status: this.selectedStatus,
      };
    },
    /**
     * Saves the ids of all filtered note takers to localstorage
     *
     * @param {NoteTakerParameters} noteTakerParameters - The filter/paging parameters to be added to the service call
     */
    saveFilteredNoteTakerIds(sortedIds: number[]): void {
      LocalStorageService.setItem(Constants.FilteredNoteTakerIds, sortedIds);
    },
    /**
     * Returns the number of note takers with the specified status
     */
    numStatus(status: NoteTakerStatus): number {
      const noteTakers: OverviewNoteTaker[] = this.rows.filter(
        (noteTaker: OverviewNoteTaker) => noteTaker.status == status
      );
      return noteTakers.length;
    },
    /**
     * Populates paging data received from the backend to be passed to the table component
     */
    populatePagingData(data: PagedList<OverviewNoteTaker>): void {
      const pagingData: IPagingData = {
        currentPage: data.currentPage,
        totalPages: data.totalPages,
        pageSize: data.pageSize,
        totalCount: data.totalCount,
        hasPrevious: data.hasPrevious,
        hasNext: data.hasNext,
      };

      this.pagingData = pagingData;
    },
    /**
     * Generates user paging data to be saved to local storage
     */
    generateUserPagingData(): IUserPagingData {
      const userPagingData: IUserPagingData = {
        sortColumn: this.sortColumn,
        sortOrder: this.ascending ? SortOrder.Asc : SortOrder.Desc,
        pageSize: this.pagingData.pageSize,
      };

      return userPagingData;
    },
    /**
     * Triggered when the user changes the page number of the table
     */
    onPageNumberChange(pageNumber: number): void {
      this.pagingData.currentPage = pageNumber;
      this.getNoteTakers();
    },
    /**
     * Triggered when the user changes the sort column/order of the table
     */
    onSortChange(columnName: string, ascending: boolean): void {
      this.sortColumn = columnName;
      this.ascending = ascending;
      LocalStorageService.setItem(
        Constants.NoteTakerTableUserPagingData,
        this.generateUserPagingData()
      );
      this.getNoteTakers();
    },
    /**
     * Triggered when the user changes the page size of the table
     */
    onPageSizeChange(pageSize: number): void {
      this.pagingData.pageSize = pageSize;
      LocalStorageService.setItem(
        Constants.NoteTakerTableUserPagingData,
        this.generateUserPagingData()
      );
      this.getNoteTakers();
    },
    /**
     * Triggered when the user changes the selected status options
     */
    onStatusChange(
      statusFilter: Record<string, Record<string, boolean>>
    ): void {
      this.setSelectedStatus(statusFilter);
      this.getNoteTakers();
    },
    /**
     * Sets selected status, called on page load and by onStatusChange
     */
    setSelectedStatus(
      statusFilter: Record<string, Record<string, boolean>>
    ): void {
      this.statusFilter = statusFilter;
      const selected_status = new Array<string>();
      this.iterateFilter(statusFilter, selected_status, "status");
      this.selectedStatus = selected_status;
    },
    /**
     * Triggered when the user enters search text
     */
    onSearchChange(searchPhrase: string): void {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.search = searchPhrase;
        this.getNoteTakers();
      }, Constants.SearchSubmissionDelayMilliseconds);
    },
    /**
     * Gets the user's paging data that has been saved to local storage
     */
    getUsersPagingData(): void {
      const userPagingData = LocalStorageService.getItem<IUserPagingData>(
        Constants.NoteTakerTableUserPagingData
      );

      this.pagingData.pageSize = 10;

      if (userPagingData) {
        this.sortColumn = userPagingData.sortColumn;
        this.ascending = userPagingData.sortOrder === SortOrder.Asc;
        this.pagingData.pageSize = userPagingData.pageSize;
      }
    },
    /**
     * Opens the modal where the user can enter a date to include fee export data from that date forward
     */
    openExportDateModal(feeExportType: FeeDataExportType): void {
      this.feeExportType = feeExportType;
      this.$refs["export-modal"].open();
    },
    /**
     * Exports fee basis data as a downloaded excel file
     */
    async exportFeeBasisData() {
      this.$refs["export-modal"].close();
      this.isLoading = true;

      const fromDate = this.feeExportFromDate
        ? this.feeExportFromDate.toISOString().substring(0, 10)
        : "";

      await DownloadService.downloadFeeData(
        this.locale,
        fromDate,
        this.feeExportType
      )
        .then(() => {
          this.isLoading = false;
          this.feeExportFromDate = null;
        })
        .catch((e: Error) => {
          this.$refs["error-modal"].open();
          console.error(e);
        });
    },
  },
  async mounted() {
    this.setSelectedStatus(this.statusFilter);
    this.getUsersPagingData();
    this.getNoteTakers();
  },
})
export default class NoteTakerList extends Vue {}
