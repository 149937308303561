
import { PropType } from "@vue/runtime-core";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    /**
     * If the accordion is shown at start
     */
    expanded: {
      type: Boolean as PropType<boolean>,
      required: false,
      default: false,
    },
  },
  data: () => ({
    /**
     * If the accordion is expanded at the moment
     */
    _expanded: false,
  }),
  methods: {
    /**
     * Gets called when the student clicks on the accordion header
     */
    toggle() {
      this._expanded = !this._expanded;
    },
  },
  created() {
    this._expanded = this.expanded;
  },
})
export default class Accordion extends Vue {}
