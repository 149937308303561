import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { style: {"display":"inline-block","margin":"0"} }
const _hoisted_3 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.students && _ctx.students.length === 1)
      ? (_openBlock(), _createElementBlock("p", _hoisted_1, [
          _createElementVNode("a", null, _toDisplayString(_ctx.students[0].firstName) + " " + _toDisplayString(_ctx.students[0].lastName), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.students && _ctx.students.length > 1)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.students.length), 1),
          _createElementVNode("span", {
            style: {"padding-left":"10px"},
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggle())),
            "aria-label": _ctx.$t('note-taker-list.show-students')
          }, [
            _createVNode(_component_font_awesome_icon, {
              icon: _ctx.shown ? 'chevron-up' : 'chevron-down'
            }, null, 8, ["icon"])
          ], 8, _hoisted_3),
          _createElementVNode("template", {
            class: _normalizeClass(_ctx.shown ? 'd-block' : 'd-none')
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.students, (student) => {
              return (_openBlock(), _createElementBlock("p", { key: student }, [
                _createElementVNode("a", null, _toDisplayString(student.firstName) + " " + _toDisplayString(student.lastName), 1)
              ]))
            }), 128))
          ], 2)
        ], 64))
      : _createCommentVNode("", true)
  ], 64))
}