import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "mb-0" }
const _hoisted_4 = ["aria-expanded"]
const _hoisted_5 = { style: {"padding-left":"10px"} }
const _hoisted_6 = { class: "card-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h2", _hoisted_3, [
        _createElementVNode("button", {
          class: "btn btn-link text-left",
          type: "button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args))),
          "aria-expanded": this._expanded
        }, [
          _renderSlot(_ctx.$slots, "button"),
          _createElementVNode("span", _hoisted_5, [
            _createVNode(_component_font_awesome_icon, {
              icon: this._expanded ? 'chevron-up' : 'chevron-down'
            }, null, 8, ["icon"])
          ])
        ], 8, _hoisted_4)
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["collapse", { show: this._expanded }])
    }, [
      _createElementVNode("div", _hoisted_6, [
        _renderSlot(_ctx.$slots, "content")
      ])
    ], 2)
  ]))
}